import * as React from 'react';
import axios from 'axios';

// import { UNSPLASH_ACCESS_KEY, UNSPLASH_SECRET_KEY } from 'gatsby-env-variables';

import { createApi } from 'unsplash-js';
// import nodeFetch from 'node-fetch';

const unsplash = createApi({
    accessKey: 'http://localhost:8001/unsplash-proxy',
    // fetch: nodeFetch.default
});

export default class ImageService {


    static async searchBySearchTerm(searchTerm) {

        return await axios.get(`http://localhost:8001/unsplash_proxy/${searchTerm}`)
    }

    static async delete(publicId) {

        return await axios.delete(`http://localhost:8001/admin/images/delete/${publicId}`);
    }
}
