import { graphql } from 'gatsby';
import * as React from 'react';

import { useDispatch } from 'react-redux';

import ImageService from '../../services/imageService';

import AddTaskIcon from '@mui/icons-material/AddTask';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import {
    Divider,
    IconButton,
    Tooltip
} from '@mui/material';

import Layout from './Layout';

import { CloudinaryContext, Image } from 'cloudinary-react';

import hexToRgba from 'hex-to-rgba';

import { siteMetadata } from '../../../gatsby-config';
import DataService from '../../services/dataService';
import { useScript } from '../../utils/hooks';

import AddIcon from '@mui/icons-material/Add';
import { setUpdatingData } from '../../redux/admin/adminSlice';


const CustomImage = ({ publicId, pageContext, siteId, imageId, setImages }) => {

    const dispatch = useDispatch();

    const [mouseOver, setMouseOver] = React.useState(false);
    const imageRef = React.createRef();

    const deleteImage = async (image) => {

        const confirmed = window.confirm('Are you sure you want to delete this image?');

        const publicId = `${pageContext.sitename}/${image.split('/')[1].split('.')[0]}`;

        dispatch(setUpdatingData({ updatingData: true }))

        if (confirmed) {
            const success = await ImageService.delete(publicId);
            if (success.status == 200) {
                DataService.deleteRecord(imageId)
                    .then( res => {
                        DataService.getAllRecords(siteMetadata.datoCmsModelIds.Image, siteId)
                            .then((results) => {
                                setImages(results);
                                dispatch(setUpdatingData({ updatingData: false }));
                            })
                    })
            }
        }
    }

    return (
        <div
            onMouseOver={() => setMouseOver(true) }
            onMouseLeave={() => setMouseOver(false) }
            style={{ 
                position: 'relative', 
                height: 'fit-content', 
                top: 10
            }}
        >
            {
                mouseOver && (
                    <div style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(62, 62, 62, 0.4)'
                    }}>
                        <IconButton
                            style={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                            }}
                            onClick={() => deleteImage(publicId)}
                        >
                            <DeleteForeverOutlinedIcon
                                style={{
                                    backgroundColor: 'red',
                                    color: 'whitesmoke'
                                }}
                            />
                        </IconButton>
                    </div>
                )
            }
            <Image
                ref={imageRef}
                publicId={publicId} 
                width={200}
                style={{
                    margin: 10
                }} 
            />
        </div>
    )
}


const ImageResult = ({ image }) => {

    const [mouseOver, setMouseOver] = React.useState(false);

    const imageRef = React.createRef();

    return (
        <div
            onMouseOver={() => setMouseOver(true) }
            onMouseLeave={() => setMouseOver(false) }
            style={{ 
                position: 'relative', 
                height: 'fit-content', 
                top: 10
            }}
        >
            {
                mouseOver && (
                    <div style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: hexToRgba(image.color, 0.5)
                    }}>
                        <IconButton
                            style={{
                                borderColor: 'whitesmoke',
                                borderStyle: 'solid',
                                borderWidth: 1,
                                borderRadius: 25,
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                width: 50,
                                height: 50,
                                
                                '&:hover': {
                                    backgroundColor: hexToRgba(image.color, 1),
                                    color: hexToRgba(image.color, 1)
                                }
                            }}
                            onClick={() => {}}
                        >
                            <AddTaskIcon 
                                style={{
                                    color: 'whitesmoke',

                                    '&:hover': {
                                        backgroundColor: hexToRgba(image.color, 1),
                                        color: hexToRgba(image.color, 1)
                                    }
                                }}
                            />
                        </IconButton>

                    </div>
                )
            }

            <img
                width={200}
                ref={imageRef} 
                src={image.urls.regular} 
                alt="Unsplash image"
                style={{
                    margin: 10
                }}
            />
        </div>
    )
}


const Images = ({data, pageContext}) => {

    // const site = useSelector( state => state.admin.site );
    // const dispatch = useDispatch();

    const [images, setImages] = React.useState(data.allDatoCmsImage.nodes);
    const [imageResults, setImageResults] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState(null);
    const [showImageResults, setShowImageResults] = React.useState(false);
    const [showUploadWidget, setShowUploadWidget] = React.useState(false);
    const [uploadWidget, setUploadWidget] = React.useState(null);

    const status = useScript(
        'https://upload-widget.cloudinary.com/global/all.js'
    );

    const [scriptStatus, setScriptStatus] = React.useState(status);

    React.useEffect(() => {
        console.log(data.datoCmsWebsite.id.split('-')[1])
    }, []);

    React.useEffect(() => {

        if (typeof window.cloudinary === 'object' && status == 'ready') {
            const widget = window.cloudinary.createUploadWidget({
                cloudName: "dbs3cwu82",
                folder: pageContext.sitename,
                resourceType: 'image',
                sources: ['local', 'url', 'unsplash'],
                uploadPreset: "vyvonknv"},
                (err, result) => {
                    
                    if (!err) {
                        switch(result.event) {
                            case 'close':
                                setShowUploadWidget(false);
                                break;
                            case 'success':
                                console.log(result.info.secure_url.split('/'));
                                DataService.createImage({
                                    name: `${pageContext.sitename}/${result.info.secure_url.split('/')[result.info.secure_url.split('/').length - 1]}`,
                                    created: new Date().toISOString(),
                                    site: data.datoCmsWebsite.id.split('-')[1]
                                }).then( res => {
                                    DataService.getAllRecords(siteMetadata.datoCmsModelIds.Image, data.datoCmsWebsite.id)
                                        .then((results) => {
                                            setImages(results);
                                        }
                                    )
                                }).catch(err => console.error(err))
                        }
                    }
                }

                
            );

            setUploadWidget(widget)
        }
    }, [status]);

    const handleShowUploadWidget = () => {

        if (showUploadWidget) {
            uploadWidget.close();
            setShowUploadWidget(false);
        } else {
            uploadWidget.open();
            setShowUploadWidget(true);
        }
    }

    const searchImagesBySearchTerm = async () => {

        const images = await ImageService.searchBySearchTerm(searchTerm);
        setImageResults(images.data.response.results);
        setShowImageResults(true);
    }

    if (status != "ready") (
        <div>Loading...</div>
    )

    return (
        <Layout>

            <h2 className="text-2xl font-bold text-center" style={{ fontFamily: 'monospace', color: "#45425a" }}>Images</h2>

            <Divider />

            <div style={{ padding: '2rem', height: '100%' }}>
                <CloudinaryContext cloudName="dbs3cwu82">
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap'
                        }}
                    >
                        {
                            images.map( (image, index) => (
                                <CustomImage
                                    key={index}
                                    pageContext={pageContext} 
                                    publicId={image.name} 
                                    siteId={data.datoCmsWebsite.id}
                                    imageId={image.id} 
                                    setImages={setImages}
                                />
                            ))
                        }
                    </div>
                </CloudinaryContext>
            </div>
            <Tooltip 
                title={"Upload Image"} 
                placement='left-start'
                className="hi"
                style={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    backgroundColor: '#45425a',
                    width: 100,
                    height: 100,
                    WebkitBoxShadow: '1px 2px 14px 1px rgba(0,0,0,0.80)',
                    boxShadow: '1px 2px 14px 1px rgba(0,0,0,0.80)' 
                }}
            >
                <IconButton 
                    onClick={() => handleShowUploadWidget() }
                >
                    <AddIcon style={{ textAlign: 'right', fontSize: 50, color: 'white' }} />
                </IconButton>
            </Tooltip>
        </Layout>
    )
}

export const query = graphql`
    query imageQuery($sitename: String) {
        allDatoCmsImage(filter: { site: {name: { eq: $sitename }}}) {
            nodes {
                id
                name
                created
            }
        }

        datoCmsWebsite(name: {eq: $sitename}) {
            id
        }
    }
`

export default Images;